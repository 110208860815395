<template>
  <div class="home">
      <el-card class="box-card">
        <div class="message-list" ref="message-list">
            <div class="message-item" v-for="(msg,i) in messageList" :key="'msg_' + i">
                <div :class="['message-item-inner', msg.userId === userId ? 'message-send' : 'message-receive']">
                    <div class="message-header">
                        <div class="message-nickname">{{msg.nickname}}</div>
                        <div class="message-time">
                        {{new Date().Format('yyyy年MM月dd日 HH:mm:ss')}}
                        </div>
                    </div>
                    <div class="message-content" v-html="msg.message">
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 50%;margin-bottom: 20px">
            <el-input size="mini" v-model="nickname">
                <template slot="prepend">
                    <span style="padding: 10px">昵称</span>
                </template>
            </el-input>
            <el-link type="primary" href="https://meiko.tzy.cool:8080/" target="_blank">如无法连接聊天室，点击此处并继续访问后回到此页面</el-link>
        </div>
        <el-input
        v-if="false"
          resize="none"
          type="textarea"
          :rows="5"
          maxlength="2000"
          @keyup.enter.native="sendMessage"
          v-model="messageInput">
        </el-input>
        <div class="message-input" @keyup.enter="sendMessage" ref="msg-input"></div>
        <div style="margin-top: 10px;overflow: hidden">
            <el-button plain style="float: right" size="mini" type="primary" @click="sendMessage">发送</el-button>
        </div>
      </el-card>
  </div>
</template>
<script>
import config from '@/js/common/config'
export default {
  data() {
        return {
            // websocket对象
            websocket: null,
            // 输入的消息内容
            messageInput: '',
            // 用户id
            userId: '',
            // 昵称
            nickname: '',
            // 消息列表
            messageList: [],
        };
    },
    created() {
        this.setUserId();
        this.initWebsocket();
    },
    methods: {
        // 设置userId
        setUserId() {
            let bigJasonStorage = localStorage.getItem('bigJasonStorage');
            try {
                bigJasonStorage = JSON.parse(bigJasonStorage);
                let userId = bigJasonStorage.userId;
                if (!userId) {
                    userId = this.uuid();
                }
                let nickname = bigJasonStorage.nickname;
                if (!nickname) {
                    nickname = userId;
                }
                localStorage.setItem('bigJasonStorage', JSON.stringify(bigJasonStorage));
            } catch (ex) {
                console.error(ex);
                let userId = this.uuid();
                bigJasonStorage = {
                    userId: userId,
                    nickname: userId,
                };
                localStorage.setItem('bigJasonStorage', JSON.stringify(bigJasonStorage));
            }
            this.userId = bigJasonStorage.userId;
            this.nickname = bigJasonStorage.nickname;
        },
        // 初始化websocket
        initWebsocket() {
            this.websocket = new WebSocket(config.websocketUrl + '?userId=' + this.userId);
            this.websocket.onopen = this.handleOpenWebsocket;
            this.websocket.onclose = this.handleCloseWebsocket;
            this.websocket.onmessage = this.handleReceiveMessage;
        },
        // websocket连接成功钩子
        handleOpenWebsocket() {
            console.log('聊天室连接成功');
        },
        // websocket断开钩子
        handleCloseWebsocket() {
            console.log('聊天室已断开，正在重连');
            setTimeout(this.initWebsocket, 3000);
        },
        // websocket收到消息
        handleReceiveMessage(e) {
            const message = JSON.parse(e.data);
            message.time = new Date();
            this.messageList.push(message);
            this.$nextTick(() => {
                this.$refs['message-list'].scrollTop = this.$refs['message-list'].scrollHeight;
            });
        },
        packageMessage() {
            return JSON.stringify({
                userId: this.userId,
                nickname: this.nickname,
                message: this.messageInput,
            });
        },
        // 发送消息
        sendMessage() {
            try {
                this.messageInput = this.$refs['msg-input'].innerHTML;
                this.websocket.send(this.packageMessage());
                this.$refs['msg-input'].innerHTML = '';
            } catch (ex) {
                console.error(ex);
                this.$message.error('消息发送失败');
            }
        },
        uuid() {
          const s = [];
          const hexDigits = "0123456789abcdef";
          for (let i = 0; i < 36; i++) {
              s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
          }
          s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
          s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
          s[8] = s[13] = s[18] = s[23] = "-";

          return s.join("");
      }
    }
}
</script>
<style>
.message-list {
    margin: 20px;
    overflow: auto;
    height: 440px;
    color: #eeeeee;
}
.message-item {
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
}
.message-item-inner {
    width: 40%;
}
.message-item-inner.message-receive {
    float: left;
}
.message-item-inner.message-send {
    float: right;
}
.message-header {
    display: flex;
    align-items: center;
}
.message-receive .message-nickname {
    color: chartreuse;
}
.message-send .message-nickname {
    color: #409eff;
}
.message-time {
    color: #dddddd;
    font-size: 12px;
    background-color: #33333355;
    padding: 2px;
    border: 1px solid #333333;
    border-radius: 3px;
    margin-left: 10px;
}
.message-content {
    margin-top: 10px;
    border: 1px solid #333333;
    border-radius: 5px;
    padding: 5px;
    background-color: #00000099;
    word-wrap: break-word;
}
.message-input {
    width: 100%;
    height: 110px;
    border: 1px solid #eeeeee;
    color: #eeeeee;
    -webkit-user-modify: read-write;
    overflow: auto;
}
</style>