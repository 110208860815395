import Vue from 'vue'
import App from './App.vue'
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element.css'
import './styles/star.css'

Vue.config.productionTip = false
Vue.use(Element, {
  size: "medium", // set element-ui default size
});
new Vue({
  render: h => h(App),
}).$mount('#app')
