<template>
<div id="app">
  <div class="container">
      <header class="header">
          <div class="header-left">
              <img class="avatar" src="./assets/images/avatar.jpg" />
              <div class="header-title">
                  <span class="nickname">{{nickname}}</span>
                  的主页
              </div>
          </div>
          <div class="header-right">
              <div class="slogan">
                  忙着活，
                  <br />
                  或者，
                  <br />
                  忙着死。
                  <br />
                  ——《肖申克的救赎》
              </div>
          </div>
      </header>
      <el-menu
          :default-active="menuIndex"
          class="header-menu"
          mode="horizontal"
          background-color="#00000099"
          text-color="#409EFF"
          active-text-color="#409EFF"
          @select="handleSelectMenu"
      >
        <el-menu-item index="home">首页</el-menu-item>
        <el-menu-item index="ai">AI助手</el-menu-item>
<!--          <el-menu-item index="chat">聊天室</el-menu-item>-->
<!--          <el-menu-item index="game_plane">飞机大战</el-menu-item>-->
      </el-menu>
      <div class="nav-content" v-show="menuIndex === 'home'">
          <home />
      </div>
<!--      <div class="nav-content" v-show="menuIndex === 'chat'">-->
<!--          <tiny-chat />-->
<!--      </div>-->
      <div class="nav-content" v-show="menuIndex === 'game_plane'">
        <plane ref="plane" />
      </div>
      <div class="nav-content" v-show="menuIndex === 'ai'">
        <div style="width: 100%;height: calc(100vh - 300px);">
          <iframe style="height: 100%;width: 100%" frameborder="none" src="https://gpt.tzy.cool/"></iframe>
        </div>
      </div>
  </div>
  <canvas id="canvas" ref="canvas"></canvas>
  <img class="lbxx" src="./assets/images/lbxx.gif" width="60" height="60px"/>
</div>
</template>

<script>
import config from '@/js/common/config'
import home from '@/components/home'
import tinyChat from '@/components/tiny-chat'
import plane from '@/components/plane'
import StarAnimation from './js/star.js'
export default {
  name: 'App',
  components: {home,tinyChat,plane},
  data(){
      return {
          // 昵称
          nickname: config.nickname,
          // 菜单索引
          menuIndex: "home"
      }
  },
  mounted() {
      this.initConfig()
      StarAnimation(this.$refs.canvas)
  },
  watch: {
    menuIndex: {
      handler(v) {
        if(v === 'game_plane') {
          this.$refs.plane.resize()
        }
      }
    }
  },
  methods: {
      // 初始化配置文件
      initConfig() {
          document.title = config.title
      },
      // 选择菜单
      handleSelectMenu(key) {
          this.menuIndex = key
      }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'rz';
  src: url("./assets/fonts/rz.ttf");
}
html,body{
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%;
}
.container{
  width: calc(100% - 200px);
  margin-left: 100px;
}
.header{
  width: 100%;
  height: 120px;
  background-color: #00000099;
  display: flex;
}
.header-left{
  line-height: 120px;
  display: flex;
}
.header-right{
  flex-grow: 1;
  text-align: right;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
}
.header-left .avatar{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 40px;
  margin-top: 16px;
  border: 4px solid #ffffff;
}
.header-left .header-title{
  color: #ffffff;
  font-size: 26px;
  margin-left: 20px;
  font-family: 'rz';
}
.header-left .header-title .nickname{
  font-size: 36px;
  color: #FF613D;
}
.header-menu{
  margin-top: 20px !important;
}
.nav-content{
  margin-top: 20px;
}
.lbxx{
  position: fixed;
  user-select: none;
  -webkit-user-drag: none;
  right: 20px;
  bottom: 20px;
}

</style>
