import Bullet from "../Bullet";

/**
 * 玩家子弹类
 */
class PlayerBullet extends Bullet{
    constructor(name,image,left,top,width,height,attack,speed){
        //super(name,image,plane.left+plane.width/2-width/2,plane.top-height,width,height,attack,speed)
        super(name,image,left,top,width,height,attack,speed)
    }
}
export default PlayerBullet
