import Spirit from "../../base/Spirit";

/**
 * 子弹类
 */
class Bullet extends Spirit{
    /**
     * 构造函数
     * @param attack 攻击力
     * @param speed  速度
     */
    constructor(name,image,left,top,width,height,attack,speed){
        super(name,image,left,top,width,height);
        this.attack = attack;
        this.speed = speed;
    }
}
export default Bullet
