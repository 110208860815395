import Spirit from "../../base/Spirit";

/**
 * 飞机类，继承精灵
 */
class Plane extends Spirit{
    /**
     *
     * @param name 名称
     * @param image 图片
     * @param left 水平位置
     * @param top  垂直位置
     * @param width 宽度
     * @param height  高度
     * @param attack 攻击力
     * @param def    防御力
     * @param hp     生命值
     */
    constructor(name,image,left,top,width,height,attack,def,hp){
        super(name,image,left,top,width,height);
        this.attack = attack;
        this.def = def;
        this.hp = hp;
    }
}

export default Plane
