import Plane from "../Plane";

const STATE_NORMAL = 1;  //正常模式
const STATE_RAYZER = 2;  //激光模式
const STATE_DASH = 3;    //冲刺模式
const STATE_FIRE = 4;    //火力全开模式
/**
 * 玩家类，继承精灵
 */
class Player extends Plane{
    /**
     *
     * @param state   玩家状态
     * @param bulletCount  单次发射子弹数量
     * @param missileCount  单次发射飞弹数量
     */
    constructor(image,left,top,width,height,attack=10,def=5,hp=10,state = STATE_NORMAL,bulletCount=1,missileCount=0){
        super("player",image,left,top,width,height,attack,def,hp);
        this.state = state;
        this.bulletCount = bulletCount;
        this.missileCount = missileCount;
    }
}

export default Player
export {STATE_NORMAL,STATE_RAYZER,STATE_DASH,STATE_FIRE}
