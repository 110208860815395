/**
 * 精灵类（每个游戏内的单位都视作精灵，如玩家，敌机，子弹等）
 */
class Spirit {
    /**
     *
     * @param name 名称
     * @param image 图片
     * @param left 水平位置
     * @param top  垂直位置
     * @param width 宽度
     * @param height 高度
     */
    constructor(name,image,left,top,width,height) {
        this.name = name;
        this.image = image;
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }
    draw(ctx){
      ctx.drawImage(this.image,this.left,this.top,this.width,this.height);
    }
}
export default Spirit
