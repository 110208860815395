<template>
    <div class="game-container" ref="container">
        <canvas @mousedown="touchStart" @mouseup="touchEnd" @mousemove="movePlayer" :width="deviceWidth"
            :height="deviceHeight" id="stage" ref="stage" >
        </canvas>
    </div>
</template>
<script>
    import Databus from "./js/runtime/databus"
    import Spirit from "./js/spirit/base/Spirit"
    import ShadeSpirit from "./js/spirit/base/ShadeSpirit"
    import Energy from "./js/spirit/extends/energy/Energy"
    import Player from "./js/spirit/extends/plane/player/Player"
    import Enemy from "./js/spirit/extends/plane/enemy/Enmey"
    import PlayerBullet from "./js/spirit/extends/bullet/player/PlayerBullet"
    import EnemyBullet from "./js/spirit/extends/bullet/enemy/EnemyBulet"
    import TrendsProp from "./js/spirit/extends/prop/TrendsProp"
    import StaticProp from "./js/spirit/extends/prop/StaticProp"

    export default {
        name: 'Game',
        data() {
            return {
                gameTimer: null, // 游戏计时器
                backgroundImage:null,
                mainMenuLoadedPercent:0,  //主界面图片加载百分比
                mainMenuImgs:[], //主界面图片
                loadingLoadedPercent:0,  //加载界面图片加载百分比
                loadingImgs:[],  //加载所需图片
                timing: 0,      //计时
                backPos: 0,     //背景偏移量
                loopMethodsPool: [], //方法池
                maxDelay: 0,    //最高延时
                deviceWidth: 0,   //设备宽度
                deviceHeight: 0,  //设备高度
                stage: 0,         //获取画布对象
                canMovePlayer: false,  //判断是否可以移动玩家
                currPosition: {},  //当前触摸位置
                posDValue: {},     //差值
                player: {},
                enemyEveryCount: 4, //每次创建敌人的数量
                databus: null,
                playerConfig: {
                    width: 100,    //玩家宽度
                    height: 80,    //玩家高度
                    energyBgHeight: 30,  //玩家血条高度
                    hp: 100,     //玩家血量初始值
                    attack: 20,  //玩家攻击力
                    bulletAttack:7,  //子弹攻击力
                    def: 20,     //玩家防御力
                    pauseBtnWidth: 40,   //暂停按钮宽度
                    pauseBtnHeight: 40,  //暂停按钮高度
                    propWidth:40,    //道具宽度
                    propHeight:40,    //道具高度
                    starXSpeed:0,     //星星道具横向移动速度
                    starYSpeed:1.5,     //星星道具纵向移动速度
                    starSocre:100,    //星星分数
                    gemXSpeed:0.5,     //宝石道具纵向移动速度
                    gemYSpeed:1.5,     //宝石道具纵向移动速度
                    gemGreenScore:1000,  //绿宝石分数
                    gemRedScore:2000,  //绿宝石分数
                    propChangeDelay:20,  //道具图片切换延时
                    bulletWidth:20,   //子弹宽度,
                    bulletHeight:60,   //子弹高度
                    bulletSpeed:3.5,   //子弹速度
                    bulletSpace:3,   //子弹间隔距离
                    bulletMaxCount:4,  //单次发射子弹最大数量
                    powerMaxTiming:0,  //火力全开时间
                    powerMaxTimingMax:5000,  //火力全开最大时间
                },
                enemyConfig: {
                    width: 80,    //宽度
                    height: 80,   //高度
                    attack: 10,   //攻击力
                    energyBgHeight: 10,  //血条高度
                    bulletAttack:10,  //子弹攻击力
                    def: 20,      //防御力
                    hp: 50,       //血量
                    ySpeed: 0.5,   //纵向移动速度
                    storingNeed: 500,  //满蓄力值
                },    //敌人统一参数
                score: 0,   //得分
                isPause: false,  //游戏是否暂停
                isGaming: false,  //游戏是否进行中
                isEnd:false,     //游戏是否结束
                bgm:null,     //背景音乐
            }
        },
        methods: {
            //处理游戏循环，定时调用方法池
            setLoop() {
                this.setMaxDelay();
                let self = this;
                this.gameTimer = setInterval(() => {
                    if (!self.isPause) {
                        self.timing++;
                        for (let fn of this.loopMethodsPool) {
                            if (self.timing % fn.delay === 0) {
                                fn.fn();
                            }
                        }
                        self.timing %= self.maxDelay;
                    }
                });
            },
            //计算最高延时（取方法池内所有延时的最小公倍数，用取余法来判断是否触发该方法，当延时达到最高延时时，当前延时归零）
            setMaxDelay() {
                function gcd(a, b) {
                    let minNum = Math.min(a, b), maxNum = Math.max(a, b), i = Math.floor(maxNum / minNum), vper = 0;
                    if (a === 0 || b === 0) {
                        return maxNum;
                    }

                    for (; i <= maxNum; i++) {
                        vper = minNum * i;
                        if (vper % maxNum === 0) {
                            return vper;
                        }
                    }
                }

                let delays = this.loopMethodsPool.map(i => i.delay);
                let onum = 0, i = 0, len = delays.length, midNum = 0;
                for (; i < len; i++) {
                    onum = Math.floor(delays[i]);//去掉小数
                    midNum = gcd(midNum, onum);
                }
                this.maxDelay = midNum;
            },
            //触摸开始
            touchStart(e) {
                let pageX = e.offsetX;
                let pageY = e.offsetY;
                if (this.isGaming) {
                    //判断是否触摸到玩家
                    if (this.judgeTouch(pageX, pageY, this.player)) {
                        this.canMovePlayer = true;
                        this.posDValue = {
                            left: pageX - this.player.left,
                            top: pageY - this.player.top
                        }
                    }
                    let huds = this.databus.spirits.find(s => s.type === "hud").spirits;
                    //判断是否触摸到暂停
                    let pauseBtn = huds.find(h => h.name === "pause");
                    if (this.judgeTouch(pageX, pageY, pauseBtn)) {
                        if(this.isPause){
                            this.isPause = false;
                            this.bgm.play();
                        }else{
                            this.isPause = true;
                            this.bgm.pause();
                            this.playAudio("pause");
                        }
                    }
                }else if(!this.isEnd){
                    if (this.judgeTouch(pageX, pageY, {left:this.deviceWidth/2 - 100,top:this.deviceHeight/2,width:200,height:60})) {
                        this.startGame();
                    }
                }
            },
            //触摸结束
            touchEnd() {
                this.canMovePlayer = false;
            },
            //判定是否触摸到某个精灵
            judgeTouch(pageX, pageY, spirit) {
                return pageX > spirit.left && pageX < spirit.left + spirit.width && pageY > spirit.top && pageY < spirit.top + spirit.height;
            },
            //玩家移动
            movePlayer(e) {
                if (this.canMovePlayer && this.isGaming && !this.isPause) {
                    let pageX = e.offsetX;
                    let pageY = e.offsetY;
                    let currPosition = {
                        left: pageX,
                        top: pageY
                    };
                    let _left = currPosition.left - this.posDValue.left;
                    if (_left < 0) {
                        _left = 0;
                    } else if (_left > this.deviceWidth - this.player.width) {
                        _left = this.deviceWidth - this.player.width;
                    }
                    let _top = currPosition.top - this.posDValue.top;
                    if (_top < 0) {
                        _top = 0;
                    } else if (_top > this.deviceHeight - this.player.height - this.playerConfig.energyBgHeight) {
                        _top = this.deviceHeight - this.player.height - this.playerConfig.energyBgHeight;
                    }
                    this.player.left = _left;
                    this.player.top = _top;
                }
            },
            /**
             * 将方法添加至方法池内
             * @param fn 方法
             */
            registMethod(fn) {
                this.loopMethodsPool.push(fn);
            },
            //获取待注册的方法
            getRegistedMethods() {
                let self = this;
                return [
                    {
                        name: "backMove",
                        delay: 2,
                        fn() {
                            if (self.isGaming) {
                                ++self.backPos;
                                self.backPos %= self.deviceHeight;
                            }
                        }
                    },
                    {
                        //创建玩家子弹方法
                        name: "createPlayerBullet",
                        delay: 20,
                        fn() {
                            if (self.isGaming) {
                                let image = self.getImg("bullet", "bullet_03");
                                let _config = self.playerConfig;
                                let _bulletCount = self.player.bulletCount;
                                let minLeft;   //子弹最小横坐标
                                let realSpace = _config.bulletWidth +_config.bulletSpace;  //实际间隔（宽度+间距）
                                //如果子弹数量为偶数
                                if(_bulletCount % 2 === 0){
                                    let num = _bulletCount/2;
                                    minLeft = self.player.left+self.player.width/2-_config.bulletWidth/2 - num*realSpace + realSpace/2;
                                }else{
                                    //如果为奇数
                                    let num = (_bulletCount-1)/2;
                                    minLeft = self.player.left+self.player.width/2-_config.bulletWidth/2 - num*realSpace;
                                }
                                for(let i = 0;i<_bulletCount;i++){
                                    let playerBullet = new PlayerBullet("playerBullet", image, minLeft + i*realSpace,self.player.top-_config.bulletHeight, _config.bulletWidth, _config.bulletHeight, _config.bulletAttack, _config.bulletSpeed);
                                    self.databus.spirits.find(s => s.type === "playerBullet").spirits.push(playerBullet);
                                }
                                self.playAudio("bullet",true);
                            }
                        }
                    },
                    {
                        //子弹移动方法
                        name: "playerBulletMove",
                        delay: 1,
                        fn() {
                            let fnCreateBulletDelay = self.loopMethodsPool.find(f => f.name === "createPlayerBullet").delay;
                            if(fnCreateBulletDelay === 10){
                                let huds = self.databus.spirits.find(s => s.type === "hud").spirits;
                                let powerMaxShade = huds.find(h=>h.name === "powerMaxShade");
                                self.playerConfig.powerMaxTiming--;
                                let fillHeight = (self.playerConfig.powerMaxTimingMax - self.playerConfig.powerMaxTiming) / self.playerConfig.powerMaxTimingMax * 40;
                                powerMaxShade.height = fillHeight;
                                powerMaxShade.top = self.deviceHeight - self.playerConfig.energyBgHeight - 20 - fillHeight;
                                if(self.playerConfig.powerMaxTiming<0){
                                    self.playerConfig.powerMaxTiming = 0;
                                    // let powerMaxSpirit = new Spirit("powerMax", imgPowerMax, 20, self.deviceHeight - self.playerConfig.energyBgHeight - 20, 40, 40);
                                    // let powerMaxShade = new ShadeSpirit("powerMaxShade",20,self.deviceHeight - self.playerConfig.energyBgHeight,40,0,'rgba(0,0,0,.5)');
                                    let powerMaxSpirit = huds.find(h=>h.name === "powerMax");
                                    huds.splice(huds.findIndex(h=>h===powerMaxSpirit),1);
                                    huds.splice(huds.findIndex(h=>h===powerMaxShade),1);
                                    self.changeDelay("createPlayerBullet",20);
                                }
                            }
                            let playerBullets = self.databus.spirits.find(s => s.type === "playerBullet").spirits;
                            let enemyBullets = self.databus.spirits.find(s => s.type === "enemyBullet").spirits;
                            for (let _s of playerBullets) {
                                _s.top -= _s.speed;
                                _s.draw(self.stage);
                                if (_s.top < -_s.height) {
                                    playerBullets.splice(playerBullets.findIndex(i => i === _s), 1);
                                }
                            }
                            for (let _s of enemyBullets) {
                                _s.top += _s.speed;
                                _s.draw(self.stage);
                                if (_s.top > self.deviceHeight) {
                                    enemyBullets.splice(enemyBullets.findIndex(i => i === _s), 1);
                                }
                            }
                        }
                    },
                    {
                        //创建敌人方法
                        name: "createEnemy",
                        delay: 1000,
                        fn() {
                            if (self.isGaming) {
                                for (let count = 0; count < self.enemyEveryCount; count++) {
                                    let randomEnemy = Math.floor(Math.random() * 5) + 1;
                                    let image = self.getImg("enemy", "enemy_" + randomEnemy);
                                    let randomX = Math.floor(Math.random() * (self.deviceWidth - 80));
                                    let _config = self.enemyConfig;
                                    let xSpeed = Math.floor(Math.random() * 15) / 10;
                                    if (randomX % 2 === 0) {
                                        xSpeed = -xSpeed;
                                    }
                                    let config = {
                                        ..._config,
                                        image,
                                        left: randomX,
                                        top: (-_config.height) * (count + 1),
                                        xSpeed
                                    };
                                    let enemy = new Enemy(config);
                                    self.drawEnemyEnergy(enemy);
                                    let randomFast = Math.floor(Math.random()*10);
                                    if(randomFast > 8){
                                        enemy.xSpeed = 0;
                                        enemy.ySpeed *= 2;
                                        enemy.level = 3;
                                    }else if(Math.abs(enemy.xSpeed) > 1){
                                        enemy.level = 2;
                                    }
                                    self.databus.spirits.find(s => s.type === "enemy").spirits.push(enemy);
                                }
                            }
                        }
                    },
                    {
                        //敌人移动方法
                        name: "enemyMove",
                        delay: 1,
                        fn() {
                            let enemies = self.databus.spirits.find(s => s.type === "enemy").spirits;
                            let huds = self.databus.spirits.find(s => s.type === "enemyEnergy").spirits;
                            for (let _s of enemies) {
                                _s.storing++;  //蓄力值+1
                                self.moveEnemyEnergy(_s);
                                //当蓄力值满足条件时，发射子弹
                                if (_s.storing === _s.storingNeed) {
                                    _s.storing = 0;
                                    let bulletImage = self.getImg("bullet", "enemyBullet1");
                                    let enemyBullet = new EnemyBullet("enemyBullet", bulletImage, _s, 10, 10, self.enemyConfig.bulletAttack, 1);
                                    self.databus.spirits.find(s => s.type === "enemyBullet").spirits.push(enemyBullet);
                                    enemyBullet.draw(self.stage);
                                }
                                _s.top += _s.ySpeed;
                                let _left = _s.left;
                                _left = _left + _s.xSpeed;
                                if (_left < 0 || _left > self.deviceWidth - _s.width) {
                                    if (_left < 0) {
                                        _left = 0;
                                    } else {
                                        _left = self.deviceWidth - _s.width;
                                    }
                                    _s.xSpeed = -_s.xSpeed;
                                }
                                _s.left = _left;
                                _s.draw(self.stage);
                                if (_s.top > self.deviceHeight - self.playerConfig.energyBgHeight) {
                                    huds.splice(huds.findIndex(h=>h === _s.energyBg),1);
                                    huds.splice(huds.findIndex(h=>h === _s.eneygyMain),1);
                                    enemies.splice(enemies.findIndex(i => i === _s), 1);
                                }
                            }
                        }
                    },
                    {
                        //星星移动方法
                        name: "starMove",
                        delay: 1,
                        fn() {
                            let props = self.databus.spirits.find(s => s.type === "star").spirits;
                            for (let _s of props) {
                                _s.currDelay++;
                                if(_s.currDelay === _s.changeDelay){
                                    _s.currDelay = 0;
                                    _s.imageIndex++;
                                    if(_s.imageIndex > 9){
                                        _s.imageIndex = 1;
                                    }
                                    _s.image = self.getImg("props","star"+_s.imageIndex);
                                }
                                _s.top += _s.ySpeed;
                                let _left = _s.left;
                                _left = _left + _s.xSpeed;
                                if (_left < 0 || _left > self.deviceWidth - _s.width) {
                                    if (_left < 0) {
                                        _left = 0;
                                    } else {
                                        _left = self.deviceWidth - _s.width;
                                    }
                                    _s.xSpeed = -_s.xSpeed;
                                }
                                _s.left = _left;
                                _s.draw(self.stage);
                                if(_s.imageIndex < 9){
                                    _s.imageIndex++;
                                }else{
                                    _s.imageIndex = 1;
                                }
                                if (_s.top > self.deviceHeight) {
                                    props.splice(props.findIndex(i => i === _s), 1);
                                }
                            }
                        }
                    },
                    {
                        //静态道具移动方法
                        name: "gemMove",
                        delay: 1,
                        fn() {
                            let spirits = self.databus.spirits;
                            let gems = spirits.find(s => s.type === "gem").spirits;
                            let funcs = spirits.find(s => s.type === "func").spirits;
                            for (let _s of gems) {
                                _s.top += _s.ySpeed;
                                let _left = _s.left;
                                _left = _left + _s.xSpeed;
                                if (_left < 0 || _left > self.deviceWidth - _s.width) {
                                    if (_left < 0) {
                                        _left = 0;
                                    } else {
                                        _left = self.deviceWidth - _s.width;
                                    }
                                    _s.xSpeed = -_s.xSpeed;
                                }
                                _s.left = _left;
                                _s.draw(self.stage);
                                if (_s.top > self.deviceHeight) {
                                    gems.splice(gems.findIndex(i => i === _s), 1);
                                }
                            }
                            for (let _s of funcs) {
                                _s.top += _s.ySpeed;
                                let _left = _s.left;
                                _left = _left + _s.xSpeed;
                                if (_left < 0 || _left > self.deviceWidth - _s.width) {
                                    if (_left < 0) {
                                        _left = 0;
                                    } else {
                                        _left = self.deviceWidth - _s.width;
                                    }
                                    _s.xSpeed = -_s.xSpeed;
                                }
                                _s.left = _left;
                                _s.draw(self.stage);
                                if (_s.top > self.deviceHeight) {
                                    funcs.splice(funcs.findIndex(i => i === _s), 1);
                                }
                            }
                        }
                    },
                    {
                        //全局碰撞检测
                        name: "checkHit",
                        delay: 1,
                        fn() {
                            let _spirit = self.databus.spirits;
                            //玩家子弹碰撞飞机检测
                            let enemies = _spirit.find(s => s.type === "enemy").spirits;
                            let playerBullets = _spirit.find(s => s.type === "playerBullet").spirits;
                            playerBullets.forEach(b => {
                                enemies.forEach(en => {
                                    //横向检测
                                    if (b.left > en.left && b.left < en.left + en.width - b.width) {
                                        //纵向检测
                                        if (b.top > en.top - b.height && b.top < en.top + en.height - 50) {
                                            //血量
                                            if (en.hp > 0) {
                                                playerBullets.splice(playerBullets.findIndex(i => i === b), 1);
                                                let damage = self.calcDPS(b, en);
                                                en.hp -= damage;
                                                en.eneygyMain.changeEnergy(damage);
                                                if (en.hp <= 0) {
                                                    self.enemyBoom(enemies, en);
                                                    let ran = Math.random()*20;
                                                    if(ran > 18){
                                                        ran = Math.floor(Math.random()*3)+1;
                                                        self.createFuncProp(en,ran);
                                                    }else{
                                                        switch (en.level) {
                                                            case 1:
                                                                self.createStar(en);
                                                                break;
                                                            case 2:
                                                                self.createGem(en,1);
                                                                break;
                                                            case 3:
                                                                self.createGem(en,2);
                                                                break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            });
                            //敌人子弹碰撞玩家检测
                            if (self.isGaming) {
                                let enemyBullets = _spirit.find(s => s.type === "enemyBullet").spirits;
                                let player = self.player;
                                enemyBullets.forEach(b => {
                                    //横向检测
                                    if (b.left > player.left && b.left < player.left + player.width - b.width) {
                                        //纵向检测
                                        if (b.top > player.top - b.height && b.top < player.top + player.height - 50) {
                                            //血量
                                            if (player.hp > 0) {
                                                let energySpirit = _spirit.find(s => s.type === "hud").spirits.find(s => s.name === "energyMain");
                                                enemyBullets.splice(enemyBullets.findIndex(i => i === b), 1);
                                                let damage = self.calcDPS(b, player);
                                                player.hp -= damage;
                                                energySpirit.changeEnergy(damage);
                                                if (player.hp <= 0) {
                                                    self.playerBoom(player);
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                            //玩家撞击道具检测
                            let stars = _spirit.find(s => s.type === "star").spirits;
                            let gems = _spirit.find(s => s.type === "gem").spirits;
                            let funcs = _spirit.find(s => s.type === "func").spirits;
                            let player = self.player;
                            //星星道具
                            stars.forEach(b => {
                                //横向检测
                                if (b.left > player.left - b.width && b.left < player.left + player.width) {
                                    //纵向检测
                                    if (b.top > player.top - b.height && b.top < player.top + player.height) {
                                        //血量检测
                                        if (player.hp > 0) {
                                            self.addScore(b);
                                            self.playAudio("addScore",true);
                                            stars.splice(stars.findIndex(i => i === b), 1);
                                        }
                                    }
                                }
                            });
                            //宝石道具
                            gems.forEach(b => {
                                //横向检测
                                if (b.left > player.left - b.width && b.left < player.left + player.width) {
                                    //纵向检测
                                    if (b.top > player.top - b.height && b.top < player.top + player.height) {
                                        //血量检测
                                        if (player.hp > 0) {
                                            self.addScore(b);
                                            self.playAudio("addGem",true);
                                            gems.splice(gems.findIndex(i => i === b), 1);
                                        }
                                    }
                                }
                            });
                            //功能型道具
                            funcs.forEach(b => {
                                //横向检测
                                if (b.left > player.left - b.width && b.left < player.left + player.width) {
                                    //纵向检测
                                    if (b.top > player.top - b.height && b.top < player.top + player.height) {
                                        //血量检测
                                        if (player.hp > 0) {
                                            self.addScore(b);
                                            switch (b.name) {
                                                case "func_1":
                                                    self.playAudio("bigbigboom",true);
                                                    enemies.forEach(en=>{
                                                        self.enemyBoom(enemies, en);
                                                    });
                                                    break;
                                                case "func_2":
                                                    self.playAudio("levelup",true);
                                                    if(self.player.bulletCount === self.playerConfig.bulletMaxCount){
                                                        //绘制火力全开hud
                                                        if(self.playerConfig.powerMaxTiming <= 0){
                                                            let imgPowerMax = self.getImg("hud", "powerMax");
                                                            let powerMaxSpirit = new Spirit("powerMax", imgPowerMax, 20, self.deviceHeight - self.playerConfig.energyBgHeight - 60, 40, 40);
                                                            let powerMaxShade = new ShadeSpirit("powerMaxShade",20,self.deviceHeight - self.playerConfig.energyBgHeight - 20,40,0,'rgba(0,0,0,.5)');
                                                            self.databus.spirits.find(s => s.type === "hud").spirits.push(powerMaxSpirit);
                                                            self.databus.spirits.find(s => s.type === "hud").spirits.push(powerMaxShade);
                                                        }
                                                        self.playerConfig.powerMaxTiming = self.playerConfig.powerMaxTimingMax;
                                                        self.changeDelay("createPlayerBullet",10)
                                                    }else{
                                                        self.player.bulletCount++;
                                                    }
                                                    break;
                                                case "func_3": 
                                                    self.playAudio("addHp",true)
                                                    let player = self.databus.spirits.find(s => s.type === "player").spirits.find(s => s.name === "player")
                                                    let backDamage
                                                    if(player.hp + 30 > self.playerConfig.hp){
                                                        backDamage = player.hp - self.playerConfig.hp;
                                                        player.hp = self.playerConfig.hp;
                                                    }else{
                                                        player.hp += 30;
                                                        backDamage = -30;
                                                    }
                                                    let energySpirit = self.databus.spirits.find(s => s.type === "hud").spirits.find(s => s.name === "energyMain")
                                                    energySpirit.changeEnergy(backDamage);
                                                    break;
                                            }
                                            funcs.splice(funcs.findIndex(i => i === b), 1);
                                        }
                                    }
                                }
                            })
                        }
                    },
                  {
                    //重绘方法
                    name: "rePaint",
                    delay: 1,
                    fn() {
                      self.stage.clearRect(0, 0, self.deviceWidth, self.deviceHeight);
                      for (let s of self.databus.spirits) {
                        for (let _s of s.spirits) {
                          _s.draw(self.stage);
                        }
                      }
                      //绘制分数
                      let scoreStr = self.score+"";
                      for(let i = 0;i < scoreStr.length;i++){
                        let numImg = self.getImg("hud","num_"+scoreStr[i]);
                        self.stage.drawImage(numImg,55 + 25 * i,8,25,29);
                      }
                    }
                  },
                  {
                    //难度增加方法
                    name: "difficulter",
                    delay: 10000,
                    fn() {
                      if(this.difficulterCount === undefined){
                        this.difficulterCount = 0
                      }else{
                        this.difficulterCount++;
                        if(this.difficulterCount === 5){
                          self.loopMethodsPool.splice(self.loopMethodsPool.findIndex(m=>m.name === "difficulter"),1)
                        }
                      }
                      self.enemyConfig.bulletAttack+=10;
                      self.enemyConfig.attack+=10;
                      self.enemyConfig.def+=10;
                    }
                  },
                ]
            },
            /**
             * 修改方法池内的延时
             * @param fnName
             * @param delay
             */
            changeDelay(fnName, delay) {
                this.loopMethodsPool.find(f => f.name === fnName).delay = delay;
                this.setMaxDelay();
            },
            //计算得分
            calcScore(en) {
                let radis = Math.abs(en.xSpeed)*10;
                if (radis < 5)
                    radis = 5;
                //分数计算公式为 纵向速度*横向速度(最低为5) + 0~100随机数
                this.score += en.ySpeed * radis * 100 + Math.floor(Math.random() * 100);
            },
            //计算得分
            addScore(prop) {
                this.score += prop.score;
            },
            //计算伤害
            calcDPS(attacker, target) {
                //target,100为减伤基数，减伤百分比计算公式为 defDamage = def/(def+100),则实际DPS为atk*(1-defDamage);
                return parseFloat((1 - target.def / (target.def + 100)) * attacker.attack);
            },
            //敌人爆炸
            enemyBoom(enemies, en) {
                let _huds = this.databus.spirits.find(s => s.type === "enemyEnergy").spirits;
                _huds.splice(_huds.findIndex(h=>h === en.energyBg),1);
                _huds.splice(_huds.findIndex(h=>h === en.eneygyMain),1);
                this.playAudio("boom",true);
                let index = 1;
                let self = this;
                this.calcScore(en);
                let timer = setInterval(() => {
                    en.image = self.getImg("enemyBoom", "explosion" + index);
                    index++;
                    if (index > 19) {
                        enemies.splice(enemies.findIndex(i => i === en), 1);
                        clearInterval(timer);
                    }
                }, 10)
            },
            //创建星星道具
            createStar(en){
                let _config = this.playerConfig;
                let imgStar = this.getImg("props","star1");
                let starProp = new TrendsProp("star",imgStar,en.left,en.top,_config.propWidth,_config.propHeight,_config.starXSpeed,_config.starYSpeed,1,_config.propChangeDelay,0,_config.starSocre);
                this.databus.spirits.find(s=>s.type === "star").spirits.push(starProp);
            },
            //创建宝石道具
            createGem(en,index){
                let _config = this.playerConfig;
                let imgGem = this.getImg("props","gem"+index);
                let score = index === 1 ? _config.gemGreenScore : index === 2 ? _config.gemRedScore : 0;
                let gemProp = new StaticProp("gem",imgGem,en.left,en.top,_config.propWidth,_config.propHeight,_config.gemXSpeed,_config.gemYSpeed,score);
                this.databus.spirits.find(s=>s.type === "gem").spirits.push(gemProp);
            },
            //创建功能型道具
            createFuncProp(en,index){
                let _config = this.playerConfig;
                let imgFunc = this.getImg("props","func_"+index);
                let funcProp = new StaticProp("func_"+index,imgFunc,en.left,en.top,_config.propWidth,_config.propHeight,_config.gemXSpeed,_config.gemYSpeed,_config.starSocre);
                this.databus.spirits.find(s=>s.type === "func").spirits.push(funcProp);
            },
            //玩家飞机爆炸
            playerBoom() {
                this.isGaming = false;
                this.isEnd = true;
                this.playAudio("bigboom");
                let index = 1;
                let self = this;
                let timer = setInterval(() => {
                    self.player.width = self.player.height = 80;
                    self.player.image = self.getImg("bigBoom", "boom" + index);
                    index++;
                    if (index > 11) {
                        let players = self.databus.spirits.find(s => s.type === "player").spirits;
                        players.length = 0;
                        clearInterval(timer);
                    }
                }, 20)
            },
            //绘制玩家
            drawPlayer() {
                let image = this.getImg("player", "Stronger");
                let _config = this.playerConfig;
                this.player = new Player(image, (this.deviceWidth - _config.width) / 2, this.deviceHeight - _config.height - _config.energyBgHeight - 5, _config.width, _config.height, _config.attack, _config.def, _config.hp);
                this.databus.spirits.find(s => s.type === "player").spirits.push(this.player);
            },
            //绘制玩家血量图
            drawHud() {
                let _config = this.playerConfig;
                //血量背景
                let imgEnergyBg = this.getImg("hud", "Energy_bg");
                let eneygyBg = new Spirit("eneryBg", imgEnergyBg, 0, this.deviceHeight - _config.energyBgHeight, this.deviceWidth, _config.energyBgHeight);
                this.databus.spirits.find(s => s.type === "hud").spirits.push(eneygyBg);
                //血条
                let imgEnergyMain = this.getImg("hud", "Eneygy");
                let eneygyMain = new Energy("energyMain", imgEnergyMain, 3, this.deviceHeight - _config.energyBgHeight + 3, this.deviceWidth - 6, _config.energyBgHeight - 6, _config.hp);
                this.databus.spirits.find(s => s.type === "hud").spirits.push(eneygyMain);
                //暂停按钮
                let imgPause = this.getImg("hud", "pause");
                let pauseSpirit = new Spirit("pause", imgPause, this.deviceWidth - _config.pauseBtnWidth - 10, 10, _config.pauseBtnWidth, _config.pauseBtnHeight);
                this.databus.spirits.find(s => s.type === "hud").spirits.push(pauseSpirit);
                //分数提示
                let imgScore = this.getImg("hud", "uiScore");
                let scoreSpirit = new Spirit("score", imgScore, 10, 10, 45, 25);
                this.databus.spirits.find(s => s.type === "hud").spirits.push(scoreSpirit);
            },
            //绘制敌人血量
            drawEnemyEnergy(en){
                let _config = this.enemyConfig;
                //血量背景
                let imgEnergyBg = this.getImg("hud", "enemyHp_bg");
                let eneygyBg = new Spirit("enemyEneryBg", imgEnergyBg, en.left, en.top - _config.energyBgHeight - 3, en.width, _config.energyBgHeight);
                this.databus.spirits.find(s => s.type === "enemyEnergy").spirits.push(eneygyBg);
                //血条
                let imgEnergyMain = this.getImg("hud", "enemyHp");
                let eneygyMain = new Energy("enemyEnergyMain", imgEnergyMain, en.left + 3,en.top - _config.energyBgHeight, en.width - 6, _config.energyBgHeight - 6, _config.hp);
                this.databus.spirits.find(s => s.type === "enemyEnergy").spirits.push(eneygyMain);
                en.energyBg = eneygyBg;
                en.eneygyMain = eneygyMain;
            },
            //移动敌人血量
            moveEnemyEnergy(en){
                if(en.energyBg && en.eneygyMain){
                    let _config = this.enemyConfig;
                    en.energyBg.left = en.left;
                    en.energyBg.top = en.top - _config.energyBgHeight - 3;
                    en.eneygyMain.left = en.left + 3;
                    en.eneygyMain.top = en.top - _config.energyBgHeight;
                }
            },
            /**
             * 根据类型获取图片
             */
            getImg(type, name) {
                return this.databus.imgs.find(i => i.type === type).imgs.find(i => i.name === name).image;
            },
            //根据名称获取音频文件
            getAudio(name,clone){
                let audio = this.databus.audios.find(a=>a.name === name).audio;
                if(clone){
                    return audio.cloneNode(true);
                }else{
                    return audio;
                }
            },
            //根据名称播放音频文件
            playAudio(name,clone){
                let audio = this.getAudio(name,clone);
                audio.play();
                if(clone){
                    audio = null;
                }
            },
            //初始化
            init() {
                let methods = this.getRegistedMethods();
                let self = this;
                methods.forEach(f => {
                    self.registMethod(f);
                });
                this.setLoop();
                setTimeout(() => {
                    self.drawPlayer();
                    self.drawHud();
                    self.isGaming = true;
                });
                this.bgm = this.getAudio("visions");
                this.bgm.autoplay = true;
                this.bgm.loop = true;
            },
            //开始游戏
            startGame(){
                let loadingBg = new Image();
                loadingBg.src = require("./assets/img/ui/mainMenu/loading_bg.jpg");
                let progressBg = new Image();
                progressBg.src = require("./assets/img/ui/mainMenu/progress_bg.png");
                let imgProgress = new Image();
                imgProgress.src = require("./assets/img/ui/mainMenu/progress.png");
                this.loadingImgs = [loadingBg,progressBg,imgProgress];
                let loadedCount = 0;
                let countAll = this.loadingImgs.length;
                let self = this;
                for(let img of this.loadingImgs){
                    img.onload = ()=>{
                        loadedCount++;
                        self.loadingLoadedPercent = Math.floor(loadedCount*100 / countAll);
                    }
                }
            },
            resize() {
                this.$nextTick(() => {
                    const containerDom = window.getComputedStyle(this.$refs.container)
                    this.deviceWidth = containerDom.width.substring(0,containerDom.width.length - 2);
                    this.deviceHeight = containerDom.height.substring(0,containerDom.height.length - 2);
                    if(this.mainMenuLoadedPercent === 100){
                        this.stage.drawImage(this.mainMenuImgs[0],0,0,this.deviceWidth,this.deviceHeight);
                        this.stage.drawImage(this.mainMenuImgs[1],this.deviceWidth/2 - 100,this.deviceHeight/2,200,60);
                        this.stage.drawImage(this.mainMenuImgs[2],this.deviceWidth/2 - 49.5,this.deviceHeight/2+90,99,54);
                        this.stage.drawImage(this.mainMenuImgs[3],this.deviceWidth/2 - 77,this.deviceHeight/2+174,154,53);
                }
                })
            }
        },
        mounted() {
            this.backgroundImage = "url("+require('./assets/img/scene/img_bg_level_1.jpg')+")";
            window.onresize = () => {
                this.resize()
            };
            let $stage = this.$refs.stage;
            this.stage = $stage.getContext("2d");
            const containerDom = window.getComputedStyle(this.$refs.container)
            this.deviceWidth = containerDom.width.substring(0,containerDom.width.length - 2);
            this.deviceHeight = containerDom.height.substring(0,containerDom.height.length - 2);
            let mainMenuBg = new Image();
            mainMenuBg.src = require("./assets/img/ui/mainMenu/bg2_startcg.jpg");
            let imgStart = new Image();
            imgStart.src = require("./assets/img/ui/mainMenu/start.png");
            let imgSetting = new Image();
            imgSetting.src = require("./assets/img/ui/mainMenu/setting.png");
            let imgTop = new Image();
            imgTop.src = require("./assets/img/ui/mainMenu/top10.png");
            this.mainMenuImgs = [mainMenuBg,imgStart,imgSetting,imgTop];
            let loadedCount = 0;
            let countAll = this.mainMenuImgs.length;
            let self = this;
            for(let img of this.mainMenuImgs){
                img.onload = ()=>{
                    loadedCount++;
                    self.mainMenuLoadedPercent = Math.floor(loadedCount*100 / countAll);
                }
            }
        },
        watch:{
            mainMenuLoadedPercent(v){
                if(v === 100){
                    this.stage.drawImage(this.mainMenuImgs[0],0,0,this.deviceWidth,this.deviceHeight);
                    this.stage.drawImage(this.mainMenuImgs[1],this.deviceWidth/2 - 100,this.deviceHeight/2,200,60);
                    this.stage.drawImage(this.mainMenuImgs[2],this.deviceWidth/2 - 49.5,this.deviceHeight/2+90,99,54);
                    this.stage.drawImage(this.mainMenuImgs[3],this.deviceWidth/2 - 77,this.deviceHeight/2+174,154,53);
                }
            },
            loadingLoadedPercent(v){
                if(v === 100){
                    this.stage.drawImage(this.loadingImgs[0],0,0,this.deviceWidth,this.deviceHeight);
                    this.stage.drawImage(this.loadingImgs[1],20,this.deviceHeight/2+50,this.deviceWidth - 40,40);
                    let progressAllwidth = this.deviceWidth - 50;
                    let progressHeight = 34;
                    this.databus = new Databus();
                    let percent;
                    let self = this;
                    Object.defineProperty(this.databus, 'percent', {
                        get: function () {
                            return percent;
                        },
                        set: function (newValue) {
                            percent = newValue;
                            self.stage.drawImage(self.loadingImgs[2],25,self.deviceHeight/2+53,progressAllwidth*percent/100,progressHeight);
                            if (newValue === 100) {
                                self.init();
                            }
                        }
                    });
                }
            }
        },
        beforeDestroy() {
            clearTimeout(this.gameTimer)
            if(this.databus && this.databus.audios) {
                this.databus.audios.forEach(a => {
                    if(a.audio.src) {
                        a.audio.src = null
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .game-container{
        width: 100%;
        height: calc(100vh - 250px);
    }
    #stage {
        background-size: 100% 100%;
    }
</style>
