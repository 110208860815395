import Plane from "../Plane";

/**
 * 敌人类，继承飞机类
 */
class Enemy extends Plane {
    constructor(config) {
        super(config.name, config.image, config.left, config.top, config.width, config.height, config.attack, config.def, config.hp);
        this.xSpeed = config.xSpeed;
        this.ySpeed = config.ySpeed;
        this.storing = 0;     //当前蓄力值，每移动1次，蓄力值+1
        this.storingNeed = config.storingNeed; //满蓄力值，当前蓄力值=满蓄力值时，发射子弹
        this.level = 1;       //等级（不同等级速度不同，掉落的加分道具也不同）
    }
}

export default Enemy
