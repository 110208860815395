/**
 * 遮罩层精灵类
 */
class ShadeSpirit {
    /**
     *
     * @param name 名称
     * @param left 水平位置
     * @param top  垂直位置
     * @param width 宽度
     * @param height 高度
     * @param color 颜色
     */
    constructor(name,left,top,width,height,color) {
        this.name = name;
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
        this.color = color;
    }
    draw(ctx){
        ctx.fillRect(this.left,this.top,this.width,this.height);
        ctx.fillStyle = this.color;
    }
}
export default ShadeSpirit
