<template>
  <div class="home">
      <el-row>
          <el-col :span="11">
              <el-input v-model="baiduKeyword" @keyup.enter.native="searchBaidu">
                  <template slot="prepend">
                      <img class="search-logo" src="../assets/images/icons/baidu.png"/>
                  </template>
                  <el-button type="primary" slot="append" icon="el-icon-search" @click="searchBaidu"></el-button>
              </el-input>
          </el-col>
          <el-col :offset="2" :span="11">
              <el-input v-model="googleKeyword" @keyup.enter.native="searchGoogle">
                  <template slot="prepend">
                      <img class="search-logo" src="../assets/images/icons/google.png"/>
                  </template>
                  <el-button type="primary" slot="append" icon="el-icon-search" @click="searchGoogle"></el-button>
              </el-input>
          </el-col>
      </el-row>
      <div class="home-content">
          <div class="link-container" v-for="(link,i) in favouriteLinks" :key="'link_type_' + i">
              <div class="type-title">{{link.type}}</div>
              <div class="link-type-container">
                  <el-tooltip v-for="(_link,i) in link.links" :key="'link_' + i" class="item" effect="dark" :content="_link.title" placement="top">
                      <div class="link-item" @click="openWindow(_link.url)">
                          <img class="icon" :src="_link.icon ? _link.icon : getFavicon(_link)" @error="handleIconError">
                          <div class="link-title">{{_link.title}}</div>
                      </div>
                  </el-tooltip>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
        return{
            // 百度搜索关键字
            baiduKeyword:'',
            // 谷歌搜索关键字
            googleKeyword:'',
            // 常用站点
            // favouriteLinks: config.favouriteLinks
            favouriteLinks: []
        }
    },
    created() {
        fetch('https://mock.apifox.cn/m1/2126770-0-default/menus').then(res => {
            return res.json()
        }).then(res => {
            this.favouriteLinks = res
        })
    },
    methods: {
        // 百度搜索
        searchBaidu() {
            window.open("https://www.baidu.com/s?wd=" + this.baiduKeyword)
        },
        // 谷歌搜索
        searchGoogle() {
            window.open("https://www.google.com/search?q=" + this.googleKeyword)
        },
        // 打开新窗口
        openWindow(url) {
            window.open(url)
        },
        // 获取图标地址
        getFavicon(link) {
            let domain = link.url.split('/'); //以“/”进行分割
            if( domain[2] ) {
                domain = domain[0] + '//' + domain[2];
            } else {
                domain = ''; //如果url不正确就取空
            }
            return domain + '/favicon.ico'
        },
        handleIconError(e) {
          e.target.src = require("@/assets/images/icons/default_icon.png")
        }
    },
}
</script>
<style>
.home .search-logo{
    height: 34px;
}
.home .home-content{
    margin-top: 5px;
    max-height: calc(100vh - 300px);
    overflow: auto;
}
.link-container{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.type-title{
    width: 120px;
    text-align: center;
    line-height: 60px;
    color: #eeeeee;
    font-size: 18px;
}
.link-type-container{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 120px)
}
.link-item{
    min-width: 140px;
    height: 40px;
    color: #409EFF;
    user-select: none;
    line-height: 40px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-indent: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 10px 10px;
}
.link-item .icon{
    margin-left: 5px;
    width: 28px;
}
.link-item:hover{
    background-color: #00000099;
}
.link-item .link-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
::-webkit-scrollbar-track-piece {
     background: #000000;
    border: 1px solid #333333;
 }

::-webkit-scrollbar {
     width: 6px;
 }

::-webkit-scrollbar-thumb {
    border: 1px solid #999999;
     border-radius: 20px;
 }
.el-input-group{
  line-height: 10px !important;
}
</style>
