import Bullet from "../Bullet";

/**
 * 敌人子弹类
 */
class EnemyBullet extends Bullet{
    constructor(name,image,plane,width,height,attack,speed){
        super(name,image,plane.left+plane.width/2-width/2,plane.top+height,width,height,attack,speed)
    }
}
export default EnemyBullet
