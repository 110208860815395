/**
 * 数据类
 */
class Databus {
    constructor(){
        this.percent = 0;
        this.loadedCount = 0;
        this.fileCount = 0;
        this.files = [
            {
                type:"player",
                files:[]
            },
            {
                type:"enemy",
                files:[]
            },
            {
                type:"props",            //道具
                files:[]
            },
            {
                type:"bullet",
                files:[]
            },
            {
                type:"enemyBoom",
                files:[]
            },
            {
                type:"bigBoom",
                files:[]
            },
            {
                type:"hud",
                files:[]
            },
            // {
            //     type:"audio",
            //     files:[]
            // }
        ];      //文件路径集合
        this.imgs = [
            {
                type:"player",
                imgs:[]
            },
            {
                type:"enemy",
                imgs:[]
            },
            {
                type:"props",            //道具
                imgs:[]
            },
            {
                type:"bullet",
                imgs:[]
            },
            {
                type:"enemyBoom",
                imgs:[]
            },
            {
                type:"bigBoom",
                imgs:[]
            },
            {
                type:"hud",
                imgs:[]
            }
        ];        //图片集合
        this.audios = [
            {
                name:"addScore",
                audio:require("../../assets/audio/addScore.mp3")
            },
            {
                name:"addHp",
                audio:require("../../assets/audio/addHp.ogg")
            },
            {
                name:"addGem",
                audio:require("../../assets/audio/addGem.mp3")
            },
            {
                name:"bigbigboom",
                audio:require("../../assets/audio/bigbigboom.wav")
            },
            {
                name:"bigboom",
                audio:require("../../assets/audio/bigboom.wav")
            },
            {
                name:"boom",
                audio:require("../../assets/audio/boom.mp3")
            },
            {
                name:"bullet",
                audio:require("../../assets/audio/bullet.ogg")
            },
            {
                name:"levelup",
                audio:require("../../assets/audio/levelup.ogg")
            },
            {
                name:"pause",
                audio:require("../../assets/audio/pause.ogg")
            },
            {
                name:"visions",
                audio:require("../../assets/music/visions.mp3")
            },
        ];
        this.spirits = [
            {
                type:"playerBullet",    //玩家子弹
                spirits:[]
            },
            {
                type:"enemyBullet",     //敌人子弹
                spirits:[]
            },
            {
                type:"player",          //玩家
                spirits:[]
            },
            {
                type:"enemy",           //敌人
                spirits:[]
            },
            {
                type:"star",            //星星道具
                spirits:[]
            },
            {
                type:"gem",            //宝石道具
                spirits:[]
            },
            {
                type:"func",            //功能型道具
                spirits:[]
            },
            {
                type:"hud",             //数据图形化
                spirits:[]
            },
            {
                type:"enemyEnergy",     //敌人血量
                spirits:[]
            }
        ];   //精灵集合
        this.getFiles();
    }
    // 获取全部要预加载的文件
    getFiles () {
        //this.fileCount = this.audios.length;
        const playerFiles = require.context('../../assets/img/player/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const enemyFiles = require.context('../../assets/img/enemy/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const bulletFiles = require.context('../../assets/img/bullet/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const enemyBoomFiles = require.context('../../assets/img/enemyBoom/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const bigBoomFiles = require.context('../../assets/img/bigBoom/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const hudFiles = require.context('../../assets/img/hud/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        const propFiles = require.context('../../assets/img/props/', true, /.(png|jpg|jpeg|gif|bmp|webp)$/).keys();
        this.setFiles(playerFiles,"player");
        this.setFiles(enemyFiles,"enemy");
        this.setFiles(bulletFiles,"bullet");
        this.setFiles(enemyBoomFiles,"enemyBoom");
        this.setFiles(bigBoomFiles,"bigBoom");
        this.setFiles(hudFiles,"hud");
        this.setFiles(propFiles,"props");
        let self = this;
        self.preloadAudios();
    }
    setFiles(files,typeName){
        let _file = this.files.find(f=>f.type === typeName);
        for (let item of files) {
            let file = this.$fileSrc(typeName+"/"+item.substring(2));
            this.fileCount++;
            _file.files.push({
                file,name:item.substring(item.lastIndexOf("/")+1,item.lastIndexOf("."))
            })
        }
        this.preloadImages(_file,typeName);
    }
    $fileSrc(name){
        let fileSrc = null;
        if (process.env.NODE_ENV === 'development') { // 开发环境
            fileSrc = require('../../assets/img/' + name)
        } else { // 生产环境
            fileSrc = require('../../assets/img/' + name)
        }
        return fileSrc
    }
    // 图片预加载
    preloadImages (imgs,typeName) {
        let self = this;
        for (let item of imgs.files) {
            let image = new Image();
            image.src = item.file;
            image.onload = () => {
                self.imgs.find(i=>i.type === typeName).imgs.push({
                    name:item.name,
                    image
                });
                self.loadedCount++;
                // 计算文件加载的百分数，绑定到percent变量
                self.percent = Math.floor(self.loadedCount / self.fileCount * 100);
                console.log(self.percent+"%");
            }
        }
    }
    //音频文件预加载
    preloadAudios () {
        // let self = this;
        // for (let item of this.audios) {
        //     let audio = new Audio(item.audio);
        //     audio.addEventListener("loadstart",() => {
        //         self.audios.find(i=>i.name === item.name).audio = audio;
        //         self.loadedCount++;
        //         // 计算文件加载的百分数，绑定到percent变量
        //         self.percent = Math.floor(self.loadedCount / self.fileCount * 100);
        //         console.log(self.percent+"%");
        //     });
        // }

        let self = this;
        for (let item of this.audios) {
            let audio = new Audio(item.audio);
            self.audios.find(i=>i.name === item.name).audio = audio;
        }
    }
}
export default Databus
