import Spirit from "../../base/Spirit";

/**
 * 血条类，继承精灵
 */
class Energy extends Spirit{
    /**
     *
     * @param name 名称
     * @param image 图片
     * @param left 水平位置
     * @param top  垂直位置
     * @param width 宽度
     * @param height  高度
     * @param energyFull  总血量
     */
    constructor(name,image,left,top,width,height,energyFull){
        super(name,image,left,top,width,height);
        this.energyFull = energyFull;
        this.energyCurr = energyFull;
        this.widthAll = width;
    }
    changeEnergy(damage){
        this.energyCurr -= damage;
        if(this.energyCurr <= 0){
            this.energyCurr = 0;
        }
        let percent = Math.floor(this.energyCurr * 100 / this.energyFull);
        this.width = Math.floor(this.widthAll * percent / 100);
    }
}

export default Energy
