import Spirit from "../../base/Spirit";

/**
 * 静态道具类，继承精灵类
 */
class StaticProp extends Spirit {
    constructor(name,image,left,top,width,height,xSpeed,ySpeed,score) {
        super(name,image,left,top,width,height);
        this.xSpeed = xSpeed;
        this.ySpeed = ySpeed;
        this.score = score;
    }
}

export default StaticProp
